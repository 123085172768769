namespace $ { export class $mol_check_box extends $mol_check {

	/// Icon $mol_icon_tick
	@ $mol_mem()
	Icon() {
		return new $mol_icon_tick()
	}

} }

