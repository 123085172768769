namespace $.$mol {
	
	export class $mol_svg_line extends $.$mol_svg_line {
		
		from() {
			return this.pos()[ 0 ]
		}
		
		from_x() {
			return this.from()[0]
		}
		
		from_y() {
			return this.from()[1]
		}
		
		to() {
			return this.pos()[ 1 ]
		}
		
		to_x() {
			return this.to()[0]
		}
		
		to_y() {
			return this.to()[1]
		}
		
	}
	
	export class $mol_svg_circle extends $.$mol_svg_circle {
		
		pos_x() {
			return this.pos()[ 0 ]
		}
		
		pos_y() {
			return this.pos()[ 1 ]
		}
		
	}
	
	export class $mol_svg_text extends $.$mol_svg_text {
		
		pos_x() {
			return this.pos()[ 0 ]
		}
		
		pos_y() {
			return this.pos()[ 1 ]
		}
		
	}
	
}
