namespace $ { export class $mol_ghost extends $mol_view {

	/// Sub $mol_view
	@ $mol_mem()
	Sub() {
		return new $mol_view()
	}

} }

