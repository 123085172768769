namespace $ { export class $mol_button_major extends $mol_button {

} }

namespace $ { export class $mol_button_minor extends $mol_button {

} }

namespace $ { export class $mol_button_danger extends $mol_button {

} }

